/**
 * 服务管理
 */
import { get, post, request } from '@/utils/http/request'
import type { PagerParams } from './types'
import type { RequestOptions } from '@/utils/http/types'
import type {
  ServicerListParams,
  ServicerListResult,
  ServiceFormSet,
  AccountListParams,
  SettleDetailRow,
  PlaceholderSet,
  ServicerUsedItem
} from './model/servicerModel'

// 服务列表
export function getServicerList(params: ServicerListParams, options?: RequestOptions) {
  return get<ServicerListResult>('sys/service/setting/page', params, options)
}

// 第三方服务添加
export function addServicer(data, options?: RequestOptions) {
  return post('sys/service/setting', data, options)
}

// 第三方服务更新
export function updateServicer(
  data,
  options: RequestOptions = {}
) {
  options.useQueryString = false
  return request({
    url: 'sys/service/setting',
    method: 'put',
    data
  }, options)
}

// 状态修改
export function changeServicerStatus(params: {
  id: number,
  status: number
}, options?: RequestOptions) {
  return get<ServicerListResult>('sys/service/setting/status', params, options)
}

// 获取详情
export function getServicerDetail(params: { id: string }, options?: RequestOptions) {
  return get<ServiceFormSet>('sys/service/detail', params, options)
}

// 服务列表
export function getAccountList(params: AccountListParams & PagerParams, options?: RequestOptions) {
  return get<ServicerListResult>('sys/service/settle/page', params, options)
}

// 账号详情
export function getAccountDetail(settleServiceId: number, options?: RequestOptions) {
  return get<ServicerUsedItem[]>('sys/service/business/list', { settleServiceId }, options)
}

// 服务商列表
export function getServicerTeam(options?: RequestOptions) {
  return get('sys/service/team/list', options)
}

// 服务商添加-更新第三方服务
export function addServicerTeam(data: {
  teamId?: string,
  sysServiceId?: string,
  addNum?: string,
}, options?: RequestOptions) {
  return post('sys/service/settle/setting', data, options)
}

// 服务商配置状态修改
export function changeSettleStatus(params: {
  id?: string,
  status?: string,
}, options?: RequestOptions) {
  return get('sys/service/settle/status', params, options)
}

// 服务商配置状态修改
export function getSettleDetail(params: {
  id?: string
}, options?: RequestOptions) {
  return get<SettleDetailRow>('sys/service/settle/detail', params, options)
}

// 配置管理列表
export function getPlaceSettingList(params: {
  keyword?: string
}, options?: RequestOptions) {
  return get<ServicerListResult>('placeholder/setting/list', params, options)
}

// 占位符列表
export function getPlaceholderList(params: {
  name?: string,
  id?: string
}, options?: RequestOptions) {
  return get('placeholder/list', params, options)
}

// 保存占位符
export function savePlaceholder(data: PlaceholderSet, options?: RequestOptions) {
  return post('placeholder/save', data, options)
}

// 占位符移动
export function movePlaceholder(
  data: {
    id: string,
    offset: number
  },
  options: RequestOptions = {}
) {
  return request({
    url: `placeholder/move?id=${data.id}&offset=${data.offset}`,
    method: 'put'
  }, options)
}

// 占位符删除
export function delPlaceholder(
  data: {
    id: string
  },
  options: RequestOptions = {}
) {
  return request({
    url: `placeholder/delete?id=${data.id}`,
    method: 'PUT'
  }, options)
}
