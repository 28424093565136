<template>
  <app-page>
    <search-bar>
      <servicer-search-form v-model="params" @submit="handleSearch" />
      <template #side>
        <el-button icon="el-icon-plus" @click="$router.push({ name: 'servicer_servicer_edit' })">新增服务</el-button>
      </template>
    </search-bar>
    <div style="min-height: calc(100vh - 1rem)">
      <el-table
        v-loading="loading"
        class="regular-table mb2"
        border
        :data="rows"
      >
        <el-table-column
          v-for="item in fields"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.minWidth"
          show-overflow-tooltip
          :formatter="formatCol"
        >
         <template #="{ row }" v-if="item.prop === 'service_url'">
            <a v-if="row.service_url" :href="row.service_url" target="_blank">点击前往</a>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="80px">
          <template #="{ row }">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="row.status"
              @change="changeStatus(row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="160px">
          <template #="{ row }">
            <el-button
              type="text"
              @click="$router.push({ name: 'servicer_account_list', query: { sysServiceId: row.id }})"
            >管理</el-button>
            <el-button
              type="text"
              @click="$router.push({ name: 'servicer_servicer_edit', query: { id: row.id } })"
            >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <app-pagination
        :current-page="pager.page"
        :page-size="pager.pageSize"
        :total="pager.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import ServicerSearchForm from './components/ServicerSearchForm.vue'
import { getServicerList, changeServicerStatus } from '@/api/servicer'
import type { ServicerListParams } from '@/api/model/servicerModel'
import { useStore } from 'vuex'
import { dateFormat } from '@/utils/date'

// List
const fields = [
  { label: '编号', prop: 'id', minWidth: 50 },
  { label: '服务名称', prop: 'service_name' },
  { label: '服务类型', prop: 'service_type', minWidth: 100 },
  { label: '服务提供商', prop: 'service_supplier', minWidth: 100 },
  { label: '服务地址', prop: 'service_url', minWidth: 100 },
  { label: '账户密码', prop: 'account_pwd', minWidth: 80 },
  { label: '客户数', prop: 'customer_num', minWidth: 70 },
  { label: '可用数量', prop: 'enable_num', minWidth: 80 },
  { label: '实际可用量', prop: 'real_num', minWidth: 80 },
  { label: '累计使用量', prop: 'grand_total', minWidth: 80 },
  { label: '服务说明', prop: 'content', minWidth: 80 }, // 暂无
  { label: '上线时间', prop: 'created_at', minWidth: 130 }
]

function toFormData(params: ServicerListParams): ServicerListParams {
  const data: ServicerListParams = {}
  const { serviceName, serviceType, status, created } = params
  if (serviceName) {
    data.serviceName = serviceName
  }
  if (serviceType) {
    data.serviceType = Number(serviceType)
  }
  if (status !== '') {
    data.status = status
  }
  if (created && created.length > 0) {
    data.startTime = dateFormat(created[0], 'YY-MM-dd') + ' 00:00:00'
    data.endTime = dateFormat(created[1], 'YY-MM-dd') + ' 23:59:59'
  }
  return data
}

export default defineComponent({
  name: 'ServicerList',
  components: { ServicerSearchForm },
  setup() {
    const loading = ref<boolean>(false)
    // 服务类型
    const store = useStore()
    const serviceEnum = store.state.enumData.enumInfo.service
    // 服务列表
    // -------------------------------
    const params = reactive<ServicerListParams>({})
    const rows = ref()
    const pager = reactive({
      page: 1,
      total: 0,
      pageSize: 10
    })
    const loadList = async (
      page: number = pager.page,
      pageSize: number = pager.pageSize
    ) => {
      loading.value = true
      const res = await getServicerList({
        ...toFormData(params),
        pageNo: page,
        pageSize: pageSize
      })
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result.data
        pager.total = res.result.total
        pager.page = page
        pager.pageSize = pageSize
      }
    }
    loadList()

    // 搜索
    const handleSearch = () => {
      loadList(1, pager.pageSize)
    }
    const handleSizeChange = (pageSize) => {
      loadList(1, pageSize)
    }
    const handleCurrentChange = (page: number) => {
      loadList(page)
    }

    const formatCol = (row, col) => {
      if (col.property === 'created_at') {
        return dateFormat(row.created_at)
      }
      if (col.property === 'service_type') {
        return serviceEnum[row[col.property]].dataValue
      }
      if (col.property === 'account_pwd') {
        return row.account_no + '/' + row.account_pwd
      }
      return row[col.property]
    }

    // 新增账户
    // -------------------------------
    const accountDialog = ref()
    const openAccountDialog = () => {
      accountDialog.value.open()
    }
    // 更改状态
    // -------------------------------
    const changeStatus = async (row) => {
      loading.value = true
      const { id, status } = row
      const res = await changeServicerStatus({
        id,
        status
      })
      loading.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        // 重新加载列表
        loadList()
      } else {
        row.status = status === 0 ? 1 : 0
      }
      return res.success
    }
    return {
      loading,
      params,
      fields,
      rows,
      // tableHeight: window.innerHeight - 200,
      formatCol,
      handleSearch,
      changeStatus,
      accountDialog,
      pager,
      serviceEnum,
      openAccountDialog,
      handleSizeChange,
      handleCurrentChange
    }
  }
})
</script>
