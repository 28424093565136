<template>
  <el-form inline v-model="formData" @submit.prevent="handleSubmit">
    <el-form-item label="服务名称" prop="serviceName">
      <el-input
        v-model.trim="formData.serviceName"
        placeholder="请输入"
        style="width: 120px"
      />
    </el-form-item>
    <el-form-item label="服务类型" prop="serviceType">
      <el-select v-model="formData.serviceType" style="width: 120px">
        <el-option label="全部" value="" />
        <el-option :label="item.dataValue" :key="item.id" v-for="item in serviceEnum" :value="item.dataKey"/>
      </el-select>
    </el-form-item>
    <el-form-item label="当前状态" prop="status">
      <el-select v-model="formData.status" style="width: 120px">
        <el-option label="全部" value="" />
        <el-option label="启用" :value="1" />
        <el-option label="禁用" :value="0" />
      </el-select>
    </el-form-item>
    <el-form-item label="上线时间" prop="created">
      <el-date-picker
        v-model="formData.created"
        type="daterange"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        style="width: 220px"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" native-type="submit" icon="el-icon-search">搜索</el-button>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import { defineComponent, PropType, toRef } from 'vue'
import { customerTypes, customerStates } from '@/config/enums/account'
import type { ServicerListParams } from '@/api/model/servicerModel'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'ServicerSearchForm',
  emits: ['submit'],
  props: {
    modelValue: {
      type: Object as PropType<ServicerListParams>,
      required: true
    }
  },
  setup(props, { emit }) {
    // 服务类型
    const store = useStore()
    const serviceEnum = store.state.enumData.enumInfo.service
    const formData = toRef(props, 'modelValue')

    const handleSubmit = () => {
      emit('submit', formData.value)
    }

    return {
      customerTypes,
      customerStates,
      formData,
      serviceEnum,
      handleSubmit
    }
  }
})
</script>
